import ComputerTopia from "../images/computertopia.store.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { github } from "../images";
import Tailwind from "../images/icons8-tailwind-css.svg";
import ReactJs from "../images/icons8-react.svg";
import Firebase from "../images/icons8-firebase.svg";

function Project() {
  return (
    <section
      id="project"
      className="h-screen p-10 text-black md:flex md:flex-col md:justify-center"
    >
      <div className="max-w-5xl mx-auto">
        <h1 className="mb-10 text-4xl font-bold text-center md:text-left">
          Project
        </h1>
        <div className="flex flex-col gap-10 mb-24 md:flex-row">
          <div className="w-full md:w-3/5">
            <div className="h-[400px] overflow-hidden">
              <img
                src={ComputerTopia}
                alt="computerTopia"
                className="w-full transition-all duration-500 transform scrolling-image"
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-10 text-center md:w-2/5">
            <h2 className="text-2xl font-bold">Computer Topia</h2>
            <p className="font-medium text-gray-400">
              Computer Topia is an online store website that allows users to
              view and buy technology products. The website provide an interface
              for user to search products, sort products, filter products based
              on categories, authentication, add items to cart, checkout items,
              and tracking ordered.
            </p>
            <div className="flex items-center justify-center gap-4">
              {/* <h2 className="text-lg font-bold">Tech Stack:</h2> */}
              <img src={ReactJs} alt="ReactJs" />
              <img src={Tailwind} alt="Tailwind" />
              <img src={Firebase} alt="Firebase" />
            </div>
            <div className="flex justify-center gap-10">
              <a
                href="https://github.com/SodaraSou/Computer-Topia-Store"
                target="_blank"
                className="font-medium hover:underline"
              >
                Code <FontAwesomeIcon icon={faGithub} />
              </a>
              <a
                href="https://computertopia.store/"
                target="_blank"
                className="font-medium hover:underline"
              >
                Live Demo <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-10 md:flex-row-reverse">
          <div className="w-full md:w-3/5">
            <div className="h-[400px] overflow-hidden">
              <img
                src={ComputerTopia}
                alt="computerTopia"
                className="w-full transition-all duration-500 transform scrolling-image"
              />
            </div>
          </div>
          <div className="flex flex-col w-full gap-10 text-center md:w-2/5">
            <h2 className="text-2xl font-bold">Computer Topia</h2>
            <p className="font-medium text-gray-400">
              Computer Topia is an online store website that allows users to
              view and buy technology products. The website provide an interface
              for user to search products, sort products, filter products based
              on categories, authentication, add items to cart, checkout items,
              and tracking ordered.
            </p>
            <div className="flex items-center justify-center gap-4">
              {/* <h2 className="text-lg font-bold">Tech Stack:</h2> */}
              <img src={ReactJs} alt="ReactJs" />
              <img src={Tailwind} alt="Tailwind" />
              <img src={Firebase} alt="Firebase" />
            </div>
            <div className="flex justify-center gap-10">
              <a
                href="https://github.com/SodaraSou/Computer-Topia-Store"
                target="_blank"
                className="font-medium hover:underline"
              >
                Code <FontAwesomeIcon icon={faGithub} />
              </a>
              <a
                href="https://computertopia.store/"
                target="_blank"
                className="font-medium hover:underline"
              >
                Live Demo <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
