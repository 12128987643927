import { daralogo } from "../images";
import { navLinks } from "../constants";

function Header() {
  return (
    <header className="fixed z-40 w-full text-black bg-white shadow-lg">
      <div className="flex items-center justify-between p-4 mx-auto max-w-7xl xl:px-0 xl:py-4">
        {/* <img src={daralogo} alt="Logo" className="w-10" /> */}
        <h1 className="text-2xl font-bold md:text-4xl">
          <span className="text-daraOrange">Sodara</span> Sou
        </h1>
        <nav className="hidden gap-10 md:flex">
          {navLinks.map((nav, index) => (
            <a
              href={nav.id}
              className="font-bold cursor-pointer hover:text-daraOrange"
              key={index}
            >
              {nav.title}
            </a>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
